<template>
  <div> 
    <b-modal 
      id="modalVoucher" 
      size="lg" 
      title="Comprobantes" 
      hide-footer
      centered>
      <voucher-upload-vue :voucher="voucher" @confirm-summary="confirmSummary"></voucher-upload-vue>
    </b-modal>
  </div>
</template>

<script>
import voucherUploadVue from './voucherUpload.vue'
export default {
  name: 'modal-voucher',
  props: ['voucher'],
  components: {voucherUploadVue},
  methods: {
    confirmSummary(data) {
      this.$emit('confirm-summary', data)
    }
  }
}
</script>

<style>

</style>