<template>
  <section id="summary-detail">
    <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
    <div class="container mt-2">
      <div class="row" v-if="!loading.first">
          <!-- Número de manifiesto -->
          <p class="col-md-2">
            <strong>
              <div>Número de manifiesto:</div>
              <span class="font-info-color">{{ summary.id }}</span>
            </strong>
          </p>
          <!-- Estado de Manifiesto -->
          <p class="col-md-2">
            <strong>
              Estado de manifiesto: <br> 
              <span class="font-info-color">{{ summary.status.name }}</span>
            </strong>
          </p>
          <!-- Courier -->
          <p class="col-md-2">
            <strong>
              Courier: <br> 
              <span class="font-info-color">{{ summary.carrier.name }}</span>
            </strong>
          </p>
          <!-- Bodega -->
          <p class="col-md-2">
            <strong>
              Bodega: <br> 
              <span class="font-info-color">{{ summary.warehouse.name }}</span>
            </strong>
          </p>
          <!-- Fecha de Creación -->
          <p class="col-md-2">
            <strong>
              Fecha de creación: <br> 
              <span class="font-info-color">{{ summary.format_updated_at }}</span>
            </strong>
          </p>
      </div>
    </div>
    <!-- Apartado de acciones -->
    <div class="row mb-1 mx-1" v-if="!loading.first">
      <div class="col-md-12 mt-2">
        <b-button
          @click="showModalPreviewer"
          variant="outline-light"
          class="push-right ml-1">
            <feather-icon icon="FileIcon" /> {{ $t('label-btn-summary-label') }}
        </b-button>
        <b-button
          @click="showModalVoucher"
          variant="outline-light"
          class="push-right">
            <feather-icon icon="UploadIcon" /> {{ summary.files ? $t('label-btn-show-voucher') : $t('label-btn-attach-voucher') }}
        </b-button>
      </div>
    </div>
    <b-card>
      <table-render 
        id="listDeriveries" 
        v-if="!loading.first"
        class="mt-2" 
        :schema="schema" 
        :rows="deliveriesList">
        <!-- Slots -->
          <template #status="scope">
            <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status.id]}`"></span> {{ scope.rowdata.status.name }}
          </template>
          <template #imported_id="scope">
            <b-button class="search-advanced font-info-color" variant="link" @click="openDeliveryDetails(scope.rowdata.id, scope.rowdata.shipper.id)">{{ scope.rowdata.imported_id }}</b-button>
          </template>
          <template #carrier="scope">
            <img :src="scope.rowdata.carrier.logo" :alt="scope.rowdata.carrier.code" height="56">
          </template>
        <!-- Slots -->
      </table-render>
      <!-- <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true"/> -->
      <div v-if="loading.first" class="table-render-skeleton">
        <b-skeleton-table
          :rows="10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
    <modal-voucher :voucher="summary.files" @confirm-summary="confirmSummary"></modal-voucher>
    <modal-previewer :previewerData="previewerData"></modal-previewer>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import SummariesService from './summaries.service'
import * as moment from 'moment'
import ModalVoucher from './ModalVoucher.vue'
import ModalPreviewer from './ModalPreviewer.vue'

export default {
  name: 'summary-detail-list',
  components: { ModalVoucher, ModalPreviewer },
  data () {
    return {
      summary: {},
      previewerData: {},
      loading: {
        first: true
      },
      // pagination: {
      //   page: 1,
      //   total: 40,
      //   limit: 20
      // },
      schema: [],
      summariesService: new SummariesService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.schema = [
        { label: 'Empresa', key: 'shipper.name', sortable: true },
        { label: 'Cliente', key: 'client', sortable: true },
        { label: 'Estado', key: 'status', sortable: true, useSlot: true },
        { label: 'Orden de flete', key: 'tracking_number', sortable: true },
        { label: 'N° de envío', key: 'imported_id', sortable: true, useSlot: true },
        { label: 'Courier', key: 'carrier', sortable: true, useSlot: true },
        { label: 'Place_level3', key: 'place.name', sortable: true },
        { label: 'Fecha de compromiso', key: 'deadline_date_formatted', sortable: true },
        { label: 'Días de atraso', key: 'outdatedDeadline', sortable: true }
      ]
      this.getSummary() 
    },
    getSummary () {
      this.loading.first = true
      this.summariesService.callService('getSummary', null, { summary_id: this.$route.params.id_summary })
        .then(resp => {
          if (resp.data.status.id === 1) this.$router.push({ name: 'error' })
          this.summary = {
            ...resp.data,
            format_updated_at: moment(resp.data.updated_at).format('DD/MM/YYYY')
          }
          this.previewerData = { 
            labels_url: this.summary.labels_url, 
            summary_url: this.summary.summary_url 
          }
          this.deliveriesList = this.summary.deliveries.map(delivery => {
            return {
              ...delivery, 
              shipper: this.summary.shipper,
              carrier: {
                ...delivery.carrier,
                logo: `https://storage.googleapis.com/enviame-misc/carriers/logos/color/${delivery.carrier.code.toLowerCase()}.png`
              }
            }
          })
        })
        .catch(error => {
          this.$alert(this.$t('msg-error-detail', {code: error}))
        })
        .finally(() => {
          this.loading.first = false
        })
    },
    openDeliveryDetails (deliveryId, companyId) {
      this.m_openWinBlank(`/deliveries/${deliveryId}`)
    },
    /**
     * Función para abrir el modal Confirmación de manifiesto
     */
    showModalVoucher () {
      this.$bvModal.show('modalVoucher')
    },
    /**
     * Función para abrir el modal de Manifiesto y etiqueta
     */
    showModalPreviewer () {
      this.$bvModal.show('modalPreviewer')
    },
    /**
     * Función que se activa post-confirmación del manifiesto (emit del componente)
     * @param {Obj} summary información relacionada al manifieso que se confirmó
     */
    confirmSummary (summary) {
      this.$bvModal.hide('modalVoucher')
      this.$success(this.$t('msg-success-confirm-summary'))
      this.getSummary() 
    }
  }
}
</script>

<style>
.font-info-color {
  color: #1c84c6;
}
.search-advanced {
  text-decoration: underline !important;
}
</style>