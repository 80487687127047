<template>
  <div>
    <b-modal 
      id="modalPreviewer" 
      size="lg" 
      title="Previsualizador" 
      hide-footer
      centered>
        <div class="mt-0 text-center">
          <b-tabs content-class="mt-3">
            <!-- Tab manifiesto -->
            <b-tab>
              <template #title>
                <div class="text-center">
                  <span class="font-weight-bold"> Manifiesto </span>
                </div>
              </template>
              <div class="col-md-12">
                <iframe width="100%" height="700" :src="previewerData.summary_url"></iframe>
                <div class="my-1 text-center">
                  <span>Si no puedes previsualizar el PDF haz <a :href="previewerData.summary_url" target="_blank">click aquí</a></span>
                </div>
              </div>
            </b-tab>
            <!-- Tab Etiquetas -->
            <b-tab>
              <template #title>
                <div class="text-center">
                  <span class="font-weight-bold"> Etiquetas </span>
                </div>
              </template>
              <div class="col-md-12">
                <iframe width="100%" height="700" :src="previewerData.labels_url"></iframe>
                <div class="my-1 text-center">
                  <span>Si no puedes previsualizar el PDF haz <a :href="previewerData.labels_url" target="_blank">click aquí</a></span>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['previewerData']
}
</script>

<style>

</style>